import React, { useMemo } from "react"
import { Link } from "gatsby"

import Title from "./Title"
import Text from "./Text"

const NewsCard = (props) => {
  const { title, link, imageSrc } = props

  const newsLink = useMemo(() => `/aktualnosci/${link}`, [link])

  return (
    <div className="news-card">
      <div className="news-card__image">
        <img src={imageSrc} alt={title} />
      </div>
      <div className="news-card__content">
        <Title as="h3" size="5" classes="news-card__title">
          {title}
        </Title>
        <Link className="news-card__read-more" to={newsLink}>
          <Text as="span" color="primary">
            Czytaj więcej
          </Text>
        </Link>
      </div>
    </div>
  )
}

export default NewsCard
