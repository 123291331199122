import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Arrow from "../assets/arrow"
import Container from "../components/Container"
import Title from "../components/Title"
import Text from "../components/Text"

const PatronSection = () => {
  return (
    <Container as="section" classes="patron">
      <div className="patron__image">
        <StaticImage
          src="../assets/images/patron.jpg"
          alt="Emilia Sczaniecka"
        />
      </div>
      <div className="patron__content">
        <Title
          as="h4"
          size="2"
          fontFamily="serif"
          color="light"
          classes="patron__title"
        >
          Emilia Sczaniecka
        </Title>
        <Text color="light">
          Emilia Sczaniecka pochodziła z jednego z rodów szlacheckich w
          Wielkopolsce. Urodziła się 20 maja 1804 r. w Brodach. Rodzicami jej
          byli Łukasz Sczaniecki oraz Weronika z Wyskota Zakrzewskich, córka
          rotmistrza wojsk polskich oraz łowczego gnieźnieńskiego. Emilia miała
          czworo rodzeństwa: dwóch braci: Stanisława i Konstantego oraz dwie
          siostry: Nimfę i Kordulę. Wczesne dzieciństwo spędziła w spokoju i
          szczęściu. W lipcu 1810 roku bardzo boleśnie przeżyła śmierć ojca. Od
          tego czasu inicjatywę w rodzinie przejęła doświadczona w zarządzaniu
          majątkiem...
        </Text>
        <Link to='/o-nas/#patron' className="patron__cta">
          <Arrow />
        </Link>
      </div>
    </Container>
  )
}

export default PatronSection
