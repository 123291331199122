import * as React from "react"

// Sections
import BannerSection from "../sections/BannerSection"
import MottoSection from "../sections/MottoSection"
import NewsesSection from "../sections/NewsesSection"
import PatronSection from "../sections/PatronSection"
import DocumentsSection from "../sections/DocumentsSection"
import Seo from "../components/seo"

import szkola from "../assets/images/szkola.jpg"
import szkola2 from "../assets/images/szkola-2.jpg"

const IndexPage = () => {
  const pathName = typeof window !== `undefined` && window.location.pathname

  if ('/' !== pathName) return <></>

  return (
    <>
      <BannerSection
        title="Szkoła Podstawowa im. Emilii Sczanieckiej w Lwówku"
        image={
          <>
            <img src={szkola} alt="szkoła" className="animated-image" />
            <img src={szkola2} alt="szkoła" className="animated-image" />
          </>
        }
      />
      <MottoSection />
      <NewsesSection />
      <PatronSection />
      <DocumentsSection />
    </>
  )
}

export const Head = () => (
  <Seo
    title="Strona główna"
    description="Szkoła Podstawowa im. Emilii Sczanieckiej we Lwówku."
  />
)

export default IndexPage
