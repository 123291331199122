import React from "react"
import { useQuery } from "urql"
import { Link } from "gatsby"

import Container from "../components/Container"
import Title from "../components/Title"
import Text from "../components/Text"
import NewsCard from "../components/NewsCard"

const NewsesSection = () => {
  const [{ data }] = useQuery({
    query: `
    query Posts{
      posts(orderBy: createdAt_DESC, first: 6) {
        content
        id
        title
        banner {
          url
        }
      }
    }
  `,
  })

  return (
    <Container as="section" classes="newses">
      <Title as="h2" size="2" classes="newses__title">
        Aktualności

        <Link to="/aktualnosci/" className="newses__link">
          <Text as="span">Wczytaj więcej</Text>
        </Link>
      </Title>
      <div className="newses__wrapper">
        {data?.posts.map(node => (
          <NewsCard
            key={node.id}
            title={node.title}
            link={node.id}
            imageSrc={node.banner.url}
          />
        ))}
      </div>
    </Container>
  )
}

export default NewsesSection
