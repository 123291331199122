import React from "react"
const Arrow = () => (
  <svg
    width="83"
    height="38"
    viewBox="0 0 83 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18.1517H80.1742C74.191 14.8277 61.9056 6.74382 60.6292 1"
      stroke="#F9F9F3"
    />
    <path
      d="M0 20.1518H80.1742C74.191 23.4758 61.9056 31.5596 60.6292 37.3035"
      stroke="#F9F9F3"
    />
  </svg>
)

export default Arrow
