import React from "react"
import { useQuery } from "urql"
import { Link } from "gatsby"

import Container from "../components/Container"
import Title from "../components/Title"
import Text from "../components/Text"
import DownloadCard from "../components/DownloadCard"

const DocumentsSection = () => {
  const [{ data }] = useQuery({
    query: `
  query Documents {
    documents(last: 4) {
      id
      title
      file {
        url
      }
    }
  }
`,
  })
  return (
    <Container as="section" classes="documents" id="dokumenty">
      <Title as="h2" size="2" classes="documents__title">
        Dokumenty

        <Link to="/dokumenty" className="documents__link">
          <Text as="span">Wczytaj więcej</Text>
        </Link>
      </Title>
      <div className="documents__wrapper">
        {data?.documents.map(({ file, title, id }) => (
          <DownloadCard key={id} title={title} downloadPath={file.url} />
        ))}
      </div>
    </Container>
  )
}

export default DocumentsSection
